<template>
    <v-container>
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <div class="mb-3">
          <v-toolbar>
            <v-toolbar-title>Profile</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div> 
        <v-row class="mt-3">
           <v-col cols="12" md="12">
                <v-toolbar dark color="panel1"><v-toolbar-title>Info</v-toolbar-title>
                    <v-spacer></v-spacer>
                <v-btn icon dark @click="save"><v-icon>save</v-icon></v-btn>
            </v-toolbar>             
                <v-card class="mt-5">
                <v-form ref="mainForm">
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field v-model="form.admin_user_id" disabled label="Rec Id" outlined></v-text-field>                                                                
                                <v-text-field v-model="form.login_id" label="Login ID" :rules="inputRules" outlined readonly></v-text-field>
                                <v-text-field v-model="form.full_name" label="Full Name" :rules="inputRules" outlined></v-text-field>
                                <v-select
                                    v-model = "form.user_type"
                                    :items="userTypeList"
                                    item-text="description"
                                    item-value="code"
                                    label="User Type"
                                    standard
                                    :rules="inputRules"
                                    outlined
                                    disabled
                                ></v-select>                                
                                <v-text-field v-model="form.start_date" label="Start Date" outlined readonly></v-text-field>
                                <v-text-field v-model="form.end_date" label="End Date" outlined readonly></v-text-field>
                                <v-text-field v-model="form.status" label="Status" outlined readonly></v-text-field>
                                <v-btn dark color="cbtnSave" @click="save">Save</v-btn>
                            </v-col>
                        </v-row>

                    </v-container>
                </v-form>

                </v-card>

            </v-col>
            <!--image-->
            <v-col cols="12" md="12">
                <v-toolbar dark color="panelSysInfo"><v-toolbar-title>System Info</v-toolbar-title>
                </v-toolbar>             
                <v-card class="mt-5">
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field v-for="info in systemInfo" :key="info.alias" :value="form[info.name]" disabled :label="info.alias" outlined></v-text-field>
                            </v-col>
                        </v-row>

                    </v-container>

                </v-card>

                
            </v-col>

        </v-row>

    </v-container>  
</template>
    
<script>
import DlgMessage from '@/components/DlgMessage'
import ApAdminUserService from "@/services/ApAdminUserService"
import ApParameterService from "@/services/ApParameterService"
export default {
  components:{DlgMessage},
  props: {
       caller: String
    },
  data() {
    return{
            //breadcrumb
            items: [
              {text:'Profile',disabled: false,href:'#',},
              {text:'View',disabled: false,href:'#',}
              ],
            status_list:[],
            userTypeList:[],
            routeParam:[],
            form:{login_id:"",admin_user_id:0,full_name:""},
            systemInfo:[
                {"name":"created_date","alias":"Created Date"},
                {"name":"created_by","alias":"Created By"},
                {"name":"created_at","alias":"Created At"},
                {"name":"updated_date","alias":"Updated Date"},
                {"name":"updated_by","alias":"Updated By"},
                {"name":"updated_at","alias":"Updated At"},
            ],

            logo:'',

            inputRules: [
             v => !!v || 'This field is required'
            ],

            cpFiles:{
                file_type:'image',
                route_type:'merchants',
                showDialog:false,
            },
            dpicker:{menu1: false,menu2: false,},

    }
  },
  async created() {
    this.routeParam['admin_user_id'] = this.$route.params.admin_user_id;
    this.viewDetail();


  },
  
  methods:{

    async viewDetail(){
        try{
            ApAdminUserService.view_own_profile(this.form).then((res) => {
                //console.log(res.data.data);
                this.form = res.data.data;
                for (const [key, value] of Object.entries(this.form)) {
                    if(value == null){
                        this.form[key] = "";
                    }
                }                
                //console.log(res.data);
                this.status_list = res.data.statusList; 
                this.getParameter();
               
            }).catch((e)=>{
                //console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error','View User',e.response.data.error_message);
            })
            ;
        } catch(e) {
            //console.log(e);
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }
    },
    async save(){
        try{
            ApAdminUserService.update_own_profile(this.form).then(() => {
                this.$refs.dlgMsg.showDlgBox(true,'success','Update Profile','Success.');
            }).catch((e)=>{
                //console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error','Update Profile',e.response.data.error_message);
            })
            ;
        } catch(e) {
            //console.log(e);
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }
    },
    
    dlgClose(){
        this.visible = false;        
    },
    async getParameter(){
            try{
                let parameter = {login_id:"",param_type:"add-admin_user"}
                ApParameterService.get_parameter(parameter).then((res) => {
                    this.userTypeList = res.data.userTypeList;
                    //console.log(res.data);
                    
                }).catch((e)=>{
                    //console.log(e.response);
                    this.$refs.dlgMsg.showDlgBox(true,'error','Get Parameter',e.response.data.error_message);
                })
                ;
            } catch(e) {
                //console.log(e);
                //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
            }
        },
  }
}
</script>